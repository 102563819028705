export const mapTagNameDisplayName = new Map([
    ['title', 'Title'],
    ['img', 'Image'],
    ['no-img', 'No Image'],
    ['double-cta', 'Double CTA'],
    ['cta', 'CTA'],
    ['no-cta', 'No CTA'],
    ['copy', 'Copy'],
    ['no-copy', 'No copy'],
    ['description', 'Description'],
    ['price', 'Price'],
    ['logo', 'Logo'],
    ['links', 'Links'],
    ['favourite', 'Favourite'],
    ['socials', 'Socials'],
    ['demo', 'Demo'],
    ['logo', 'Logo'],
    ['promocode', 'Promocode'],
    ['legal', 'Legal'],
    ['label', 'Label'],
    ['banner', 'Banner'],
    ['divider', 'Divider'],
    ['header', 'Header'],
    ['footer', 'Footer']
  ]);

export const getSectionName = new Map(
    [
      ['header', 'Header'],
      ['hero', 'Header'],
      ['article', 'Article'],
      ['gallery', 'Gallery'],
      ['store', 'Store'],
      ['banner', 'Banner'],
      ['highlights', 'Highlights'],
      ['cta', 'CTA'],
      ['footer', 'Footer'],
      ['demo', 'Demo']
    ]);

export const getClientName = new Map(
    [
      ['pardon.jigsaw.dignity', 'glovo'],
      ['draining.believer.pioneered', 'blc'],
      ['having.paddle.pots', 'demo'],
      ['new.demo', 'demo-new']
    ]);