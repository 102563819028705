const demoSectionArray = [
    { value: 'hero', label: 'Hero' },
    { value: 'article', label: 'Article' },
    { value: 'gallery', label: 'Gallery' },
    { value: 'store', label: 'Store' },
    { value: 'banner', label: 'Banner' },
    { value: 'highlights', label: 'Highlights' },
    { value: 'cta', label: 'CTA' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
    { value: 'demo', label: 'Demo' },
];

const demoTagArray = [
    { value: 'title', label: 'Title' },
    { value: 'img', label: 'Image' },
    { value: 'no-img', label: 'No Image' },
    { value: 'cta', label: 'CTA' },
    { value: 'double-cta', label: 'Double CTA' },
    { value: 'no-cta', label: 'No CTA' },
    { value: 'copy', label: 'Copy' },
    { value: 'no-copy', label: 'No copy' },
    { value: 'description', label: 'Description' },
    { value: 'price', label: 'Price' },
    { value: 'logo', label: 'Logo' },
    { value: 'links', label: 'Links' },
    { value: 'favourite', label: 'Favourite' },
    { value: 'socials', label: 'Socials' },
    { value: 'demo', label: 'Demo' },
];

const glovoSectionArray = [
    { value: 'style', label: 'Style' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
    { value: 'central', label: 'Central' },
    { value: 'content', label: 'Content' },
    { value: 'highlight', label: 'Highlight' },
    { value: 'partner', label: 'Partner' },
    { value: 'express', label: 'Express' },
    { value: 'banner', label: 'Banner' },
    { value: 'element', label: 'Element' },
  ];

const glovoTagArray = [
    { value: 'logo', label: 'Logo' },
    { value: 'title', label: 'Title' },
    { value: 'copy', label: 'Copy' },
    { value: 'promocode', label: 'Promocode' },
    { value: 'cta', label: 'CTA' },
    { value: 'legal', label: 'Legal' },
    { value: 'label', label: 'Label' },
    { value: 'img', label: 'Image' },
    { value: 'price', label: 'Price' },
    { value: 'divider', label: 'Divider' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
];

const newDemoSectionArray = [
    { value: 'style', label: 'Style' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
    { value: 'central', label: 'Central' },
    { value: 'content', label: 'Content' },
    { value: 'highlight', label: 'Highlight' },
    { value: 'partner', label: 'Partner' },
    { value: 'element', label: 'Element' },
];

const newDemoTagArray = [
    { value: 'logo', label: 'Logo' },
    { value: 'title', label: 'Title' },
    { value: 'copy', label: 'Copy' },
    { value: 'promocode', label: 'Promocode' },
    { value: 'cta', label: 'CTA' },
    { value: 'legal', label: 'Legal' },
    { value: 'label', label: 'Label' },
    { value: 'img', label: 'Image' },
    { value: 'price', label: 'Price' },
    { value: 'divider', label: 'Divider' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
];

const blcSectionArray = [
    { value: 'header', label: 'Header' },
    { value: 'hero', label: 'Hero' },
    { value: 'offers', label: 'Offers' },
    { value: 'categories', label: 'Categories' },
    { value: 'highlight', label: 'Highlight' },
    { value: 'central', label: 'Central' },
    { value: 'footer', label: 'Footer' },
    { value: 'element', label: 'Element' },
];

const blcTagArray = [
    { value: 'logo', label: 'Logo' },
    { value: 'title', label: 'Title' },
    { value: 'copy', label: 'Copy' },
    { value: 'promocode', label: 'Promocode' },
    { value: 'cta', label: 'CTA' },
    { value: 'legal', label: 'Legal' },
    { value: 'label', label: 'Label' },
    { value: 'img', label: 'Image' },
    { value: 'price', label: 'Price' },
    { value: 'offer', label: 'Offer' },
    { value: 'icon', label: 'Icon' },
    { value: 'divider', label: 'Divider' },
    { value: 'header', label: 'Header' },
    { value: 'footer', label: 'Footer' },
    { value: 'banner', label: 'Banner' },
];

export const getSectionArray = (id) => {
    if (id === 'glovo') return glovoSectionArray;
    else if (id === 'blc') return blcSectionArray;
    else if (id === 'demo-new') return newDemoSectionArray;
    else return demoSectionArray;
};

export const getTagArray = (id) => {
    if (id === 'glovo') return glovoTagArray;
    else if (id === 'blc') return blcTagArray;
    else if (id === 'demo-new') return newDemoTagArray;
    else return demoTagArray;
};